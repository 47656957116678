<template>
  <full-view title="Address">
    <template v-slot:toolbar>
      <v-stepper v-if="initAccount" v-model="step" class="secondary" style="box-shadow: none;">
        <v-stepper-header>
          <v-stepper-step :complete="true" step="1" color="secondary darken-3">
            <div class="v-stepper__label" style="color: #4d977b;">Select Your Region</div>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="false" step="2">
            Add Address
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <v-btn large depressed color="primary" @click="save">
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-card flat>
      <v-card-text class="overflow-y-auto px-0 pt-0" :style="getViewHeightStyle(56)">
        <v-form ref="form" v-model="valid">
          <v-card class="ma-5">
            <v-img>
              <pin-location-map v-model="marker" />
            </v-img>

            <v-card-text>
              <v-switch
                inset
                v-model="address.active"
                label="Default Address"
              />
              <br />
              <v-row>
                <v-col cols="12" sm="7">
                  <v-text-field
                    label="Address Title"
                    :rules="rules.title"
                    clearable
                    outlined
                    v-model="address.title"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-text-field
                    label="GPS"
                    outlined
                    :value="
                      `${parseFloat(marker.latitude).toFixed(5)}/${parseFloat(
                        marker.longitude
                      ).toFixed(5)}`
                    "
                    readonly
                    background-color="grey lighten-4"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Building #"
                    :rules="rules.buildingNumber"
                    clearable
                    outlined
                    v-model="address.buildingNumber"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-text-field
                    label="Street Tite"
                    :rules="rules.street"
                    clearable
                    outlined
                    v-model="address.street"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                label="Area"
                clearable
                outlined
                v-model="address.area"
              >
              </v-text-field>
              <v-textarea
                label="More Description"
                clearable
                outlined
                v-model="address.description"
                rows="3"
              >
              </v-textarea>
            </v-card-text>
            <v-divider />

            <v-card-actions>
              <v-btn large depressed color="primary" @click="save">
                {{ $t("general.save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FullView from "@/common/layouts/FullView";
import PinLocationMap from "@/common/components/PinLocationMap";

export default {
  name: "Address",
  components: { FullView, PinLocationMap },
  data() {
    return {
      step: 2,
      valid: false,
      initAccount: null,
      marker: {
        latitude: 12.75896,
        longitude: 44.89213,
      },
      address: {
        id: null,
        title: null,
        buildingNumber: null,
        latitude: 12.75896,
        longitude: 44.89213,
        sreet: null,
        area: null,
        description: null,
      },
      rules: {
        title: [(v) => !!v || "Address Title is required"],
        buildingNumber: [(v) => !!v || "Building Number is required"],
        street: [(v) => !!v || "Street title is required"],
      },
    };
  },
  mounted() {
    this.initAccount = !this.getCurrentUser.activeAddress;
    if (this.isEditMode) {
      let a = this.getAddressById(this.getAddressId);
      if (a && a !== "new") {
        this.address = a;
        this.marker = { latitude: a.latitude, longitude: a.longitude };
      } else {
        this.loadAddresses().then(() => {
          this.address = this.getAddressById(this.getAddressId);
          this.marker = {
            latitude: this.address.latitude,
            longitude: this.address.longitude,
          };
        });
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("addresses", ["getAddressById"]),

    getAddressId() {
      return parseInt(this.$route.params.addressId);
    },
    isEditMode() {
      return this.getAddressId !== "new";
    },
  },
  methods: {
    ...mapActions("addresses", ["loadAddresses", "saveAddress"]),

    save() {
      this.$refs.form.validate();

      setTimeout(() => {
        if (this.valid) {
          this.saveAddress({
            item: {
              ...this.address,
              latitude: this.marker.latitude,
              longitude: this.marker.longitude,
            },
            editMode: this.isEditMode,
          }).then(() =>
            this.$$router.push({
              name: "addresses",
              params: this.$route.params,
            })
          );
        }
      }, 300);
    },
  },
};
</script>
